import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { PAGE_TITLE } from '../../utils/config';

const Index = () => {
    useEffect(() => {
        const mainScript = document.createElement('script');
        mainScript.src = '/js/main.js';
        mainScript.async = true;
        document.body.appendChild(mainScript);
    }, []);
    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE.home}</title>
            </Helmet>
            <div className="qrt-curtain" />
            <div id="qrt-dynamic-content" className="qrt-dynamic-content">
                <div className="qrt-content" id="qrt-scroll-content">
                    <div className="swiper-container qrt-main-slider-onepage">
                        <div className="qrt-slider-pagination">
                            <div className="swiper-pagination swiper-main-pagination" />
                        </div>
                        <div className="qrt-slider-navigation qrt-absolute">
                            <div className="qrt-slider-nav-btn qrt-main-prev qrt-cursor-scale qrt-cursor-color">
                                <i className="fas fa-arrow-left" />
                                <span>prev</span>
                            </div>
                            <div className="qrt-slider-nav-btn qrt-main-next qrt-cursor-scale qrt-cursor-color">
                                <span>next</span>
                                <i className="fas fa-arrow-right" />
                            </div>
                        </div>
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="qrt-project-cover">
                                    <div className="qrt-image-frame">
                                        <img
                                            className=""
                                            src="img/projects/original-size/1.jpg"
                                            alt="project cover"
                                            data-swiper-parallax={400}
                                            data-swiper-parallax-scale="1.4"
                                        />
                                    </div>
                                    <div className="qrt-overlay">
                                        <div
                                            className="qrt-parallax-fix"
                                            data-swiper-parallax-x={-400}
                                            data-swiper-parallax-duration={1000}
                                        >
                                            <div className="qrt-banner-title">
                                                <h1 className="qrt-white qrt-mb-30">
                                                    Architecture - <br /> petrified music
                                                </h1>
                                                <div className="qrt-divider-2" />
                                                <div className="qrt-text qrt-white qrt-mb-20">
                                                    Change is an opportunity! We want to lead people towards durable
                                                    solutions and designs that enhance feelings of closeness, and
                                                    hopefully, happiness.
                                                </div>
                                                <a
                                                    href="about-us.html"
                                                    className="qrt-btn qrt-btn-md qrt-btn-color qrt-cursor-scale qrt-anima-link qrt-mb-20"
                                                >
                                                    <span>Explore now</span>
                                                    <i className="fas fa-arrow-right" />
                                                </a>
                                                <a
                                                    href="contact.html"
                                                    className="qrt-btn qrt-btn-md qrt-btn-border qrt-cursor-scale qrt-anima-link qrt-mb-20"
                                                >
                                                    <span>Contact</span>
                                                    <i className="fas fa-arrow-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="qrt-project-cover">
                                    <div className="qrt-image-frame">
                                        <img
                                            className=""
                                            src="img/projects/original-size/3.jpg"
                                            alt="project cover"
                                            data-swiper-parallax={400}
                                            data-swiper-parallax-scale="1.4"
                                        />
                                    </div>
                                    <div className="qrt-overlay">
                                        <div
                                            className="qrt-parallax-fix"
                                            data-swiper-parallax-x={-400}
                                            data-swiper-parallax-duration={1000}
                                        >
                                            <div className="qrt-banner-title">
                                                <h1 className="qrt-white qrt-mb-30">
                                                    Architecture is <br />
                                                    about creating order
                                                </h1>
                                                <div className="qrt-divider-2" />
                                                <div className="qrt-text qrt-white qrt-mb-20">
                                                    Change is an opportunity! We want to lead people towards durable
                                                    solutions and designs that enhance feelings of closeness, and
                                                    hopefully, happiness.
                                                </div>
                                                <a
                                                    href="about-us.html"
                                                    className="qrt-btn qrt-btn-md qrt-btn-color qrt-cursor-scale qrt-anima-link qrt-mb-20"
                                                >
                                                    <span>Explore now</span>
                                                    <i className="fas fa-arrow-right" />
                                                </a>
                                                <a
                                                    href="contact.html"
                                                    className="qrt-btn qrt-btn-md qrt-btn-border qrt-cursor-scale qrt-anima-link qrt-mb-20"
                                                >
                                                    <span>Contact</span>
                                                    <i className="fas fa-arrow-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="qrt-project-cover">
                                    <div className="qrt-image-frame">
                                        <img
                                            className=""
                                            src="img/projects/original-size/2.jpg"
                                            alt="project cover"
                                            data-swiper-parallax={400}
                                            data-swiper-parallax-scale="1.4"
                                        />
                                    </div>
                                    <div className="qrt-overlay">
                                        <div
                                            className="qrt-parallax-fix"
                                            data-swiper-parallax-x={-400}
                                            data-swiper-parallax-duration={1000}
                                        >
                                            <div className="qrt-banner-title">
                                                <h1 className="qrt-white qrt-mb-30">
                                                    Our knowledge <br />
                                                    is your property
                                                </h1>
                                                <div className="qrt-divider-2" />
                                                <div className="qrt-text qrt-white qrt-mb-20">
                                                    Change is an opportunity! We want to lead people towards durable
                                                    solutions and designs that enhance feelings of closeness, and
                                                    hopefully, happiness.
                                                </div>
                                                <a
                                                    href="about-us.html"
                                                    className="qrt-btn qrt-btn-md qrt-btn-color qrt-cursor-scale qrt-anima-link qrt-mb-20"
                                                >
                                                    <span>Explore now</span>
                                                    <i className="fas fa-arrow-right" />
                                                </a>
                                                <a
                                                    href="contact.html"
                                                    className="qrt-btn qrt-btn-md qrt-btn-border qrt-cursor-scale qrt-anima-link qrt-mb-20"
                                                >
                                                    <span>Contact</span>
                                                    <i className="fas fa-arrow-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="qrt-content-frame">
                        <div className="qrt-left">
                            <div className="row">
                                <div className="col-lg-4">
                                    <h3 className="qrt-mb-40">
                                        We are creative <br />
                                        building - design company
                                    </h3>
                                </div>
                                <div className="col-lg-8 qrt-mb-25">
                                    <p>
                                        We have intentionally never developed a stylistic formula for our work, which is
                                        why our projects never quite look or feel the same. Each of our spaces is the
                                        result of our ability to listen, which has turned our designs and buildings into
                                        personal reflections of our clients’ personalities and values, which we
                                        co-construct through research, workshops and surveys.
                                    </p>
                                    <p>
                                        In our studio, every project is an open ended enquiry, helping people to
                                        challenge their existing state and work towards an alternative, desired one. We
                                        provide guidance, expertise and experience across all phases in the
                                        architectural process, ranging from thinking, to designing, building, and
                                        ultimately finding the right way to communicate the results.
                                    </p>
                                </div>
                                <div className="col-lg-12">
                                    <blockquote>
                                        Change is an opportunity! We want to lead people towards durable solutions and
                                        designs that enhance feelings of closeness, wellbeing and hopefully, happiness.
                                    </blockquote>
                                </div>
                            </div>
                            <div className="qrt-divider" />
                            <div className="row">
                                <div className="col-lg-3 col-sm-6">
                                    <div className="qrt-counter-frame">
                                        <div className="qrt-counter-box">
                                            <span className="qrt-counter">10</span>
                                        </div>
                                        <h5>Years Experience</h5>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="qrt-counter-frame">
                                        <div className="qrt-counter-box">
                                            <span className="qrt-counter">143</span>
                                        </div>
                                        <h5>Completed Projects</h5>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="qrt-counter-frame">
                                        <div className="qrt-counter-box">
                                            <span className="qrt-counter">114</span>
                                        </div>
                                        <h5>Happy Customers</h5>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="qrt-counter-frame">
                                        <div className="qrt-counter-box">
                                            <span className="qrt-counter">20</span>
                                        </div>
                                        <h5>Honors and Awards</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="qrt-divider qrt-space-fix" />
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3 className="qrt-mb-40">Our team</h3>
                                </div>
                                <div className="col-lg-3">
                                    <div className="qrt-team-member">
                                        <a
                                            href="about-me.html"
                                            className="qrt-cursor-scale qrt-team-item qrt-anima-link"
                                        >
                                            <img src="img/team/tm1.jpg" alt="team member" />
                                        </a>
                                        <div className="qrt-team-member-description">
                                            <div className="qrt-member-name">
                                                <h4 className="qrt-white qrt-qrt-mb-5">Viktoria Freeman</h4>
                                                <span>Chief Architect</span>
                                            </div>
                                            <a
                                                href="about-me.html"
                                                className="qrt-cursor-scale qrt-member-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="qrt-team-member">
                                        <a
                                            href="about-me.html"
                                            className="qrt-cursor-scale qrt-team-item qrt-anima-link"
                                        >
                                            <img src="img/team/tm2.jpg" alt="team member" />
                                        </a>
                                        <div className="qrt-team-member-description">
                                            <div className="qrt-member-name">
                                                <h4 className="qrt-white qrt-qrt-mb-5">Paul trueman</h4>
                                                <span>Chief Architect</span>
                                            </div>
                                            <a
                                                href="about-me.html"
                                                className="qrt-cursor-scale qrt-member-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="qrt-team-member">
                                        <a
                                            href="about-me.html"
                                            className="qrt-cursor-scale qrt-team-item qrt-anima-link"
                                        >
                                            <img src="img/team/tm3.jpg" alt="team member" />
                                        </a>
                                        <div className="qrt-team-member-description">
                                            <div className="qrt-member-name">
                                                <h4 className="qrt-white qrt-qrt-mb-5">Emma Newman</h4>
                                                <span>Chief Architect</span>
                                            </div>
                                            <a
                                                href="about-me.html"
                                                className="qrt-cursor-scale qrt-member-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="qrt-team-member">
                                        <a
                                            href="about-me.html"
                                            className="qrt-cursor-scale qrt-team-item qrt-anima-link"
                                        >
                                            <img src="img/team/tm4.jpg" alt="team member" />
                                        </a>
                                        <div className="qrt-team-member-description">
                                            <div className="qrt-member-name">
                                                <h4 className="qrt-white qrt-qrt-mb-5">Anna Oldman</h4>
                                                <span>Chief Architect</span>
                                            </div>
                                            <a
                                                href="about-me.html"
                                                className="qrt-cursor-scale qrt-member-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="qrt-divider qrt-space-fix" />
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3 className="qrt-mb-40">Prices</h3>
                                </div>
                                <div className="col-lg-3">
                                    <div className="qrt-a qrt-price">
                                        <div className="qrt-price-body">
                                            <h5 className="qrt-mb-20">Starter Price</h5>
                                            <div className="qrt-price-cost">
                                                <div className="qrt-number">
                                                    FREE<sup>*</sup>
                                                </div>
                                            </div>
                                            <ul className="qrt-price-list">
                                                <li>Ui Design</li>
                                                <li>Web Development</li>
                                                <li className="qrt-empty-item">Logo design</li>
                                                <li className="qrt-empty-item">SEO optimization</li>
                                                <li className="qrt-empty-item">Wordpress integration</li>
                                            </ul>
                                            <a
                                                className="qrt-btn qrt-btn-md qrt-cursor-scale qrt-anima-link"
                                                href="contact.html"
                                            >
                                                <span>Order now</span>
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                            <div className="qrt-asterisk">
                                                <sup>*</sup>Free only when ordering paid services
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="qrt-a qrt-price qrt-popular-price">
                                        <div className="qrt-price-body">
                                            <h5 className="qrt-mb-20">Hourly payment</h5>
                                            <div className="qrt-price-cost">
                                                <div className="qrt-number">
                                                    <span>$</span>29<span>h</span>
                                                </div>
                                            </div>
                                            <ul className="qrt-price-list">
                                                <li>Ui Design</li>
                                                <li>Web Development</li>
                                                <li>Logo design</li>
                                                <li className="qrt-empty-item">SEO optimization</li>
                                                <li className="qrt-empty-item">Wordpress integration</li>
                                            </ul>
                                            <a
                                                className="qrt-btn qrt-btn-md qrt-btn-color qrt-cursor-scale qrt-anima-link"
                                                href="contact.html"
                                            >
                                                <span>Order now</span>
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="qrt-a qrt-price">
                                        <div className="qrt-price-body">
                                            <h5 className="qrt-mb-20">Part time</h5>
                                            <div className="qrt-price-cost">
                                                <div className="qrt-number">
                                                    <span>$</span>1999<span>m</span>
                                                </div>
                                            </div>
                                            <ul className="qrt-price-list">
                                                <li>Ui Design</li>
                                                <li>Web Development</li>
                                                <li>Logo design</li>
                                                <li>SEO optimization</li>
                                                <li className="qrt-empty-item">Wordpress integration</li>
                                            </ul>
                                            <a
                                                className="qrt-btn qrt-btn-md qrt-cursor-scale qrt-anima-link"
                                                href="contact.html"
                                            >
                                                <span>Order now</span>
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="qrt-a qrt-price">
                                        <div className="qrt-price-body">
                                            <h5 className="qrt-mb-20">Full time</h5>
                                            <div className="qrt-price-cost">
                                                <div className="qrt-number">
                                                    <span>$</span>2999<span>m</span>
                                                </div>
                                            </div>
                                            <ul className="qrt-price-list">
                                                <li>Ui Design</li>
                                                <li>Web Development</li>
                                                <li>Logo design</li>
                                                <li>SEO optimization</li>
                                                <li>Wordpress integration</li>
                                            </ul>
                                            <a
                                                className="qrt-btn qrt-btn-md qrt-cursor-scale qrt-anima-link"
                                                href="contact.html"
                                            >
                                                <span>Order now</span>
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="qrt-divider qrt-space-fix" /> */}
                            {/* <div className="row">
                                <div className="col-lg-12">
                                    <h3 className="qrt-mb-40">Testimonials</h3>
                                </div>
                                <div className="col-lg-12">
                                    <div
                                        className="swiper-container qrt-testimonials-slider"
                                        style={{ overflow: 'visible' }}
                                    >
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <div className="qrt-testimonial">
                                                    <div className="qrt-testimonial-header">
                                                        <img src="img/testimonials/t1.jpg" alt="customer" />
                                                        <div className="qrt-testimonial-name">
                                                            <h4 className="qrt-mb-5">Paul Trueman</h4>
                                                            <div className="qrt-el-suptitle">Chief Architect</div>
                                                        </div>
                                                    </div>
                                                    <div className="qrt-testimonial-text">
                                                        We worked with Quarty Studio for approximately 2 years on the
                                                        complete overhaul of our house. This included the design of the
                                                        project, and the execution of the work. Quarty provided
                                                        excellent design ideas but were also indispensable in managing
                                                        the practical side of the build.
                                                    </div>
                                                    <ul className="qrt-stars">
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="qrt-testimonial">
                                                    <div className="qrt-testimonial-header">
                                                        <img src="img/testimonials/t2.jpg" alt="customer" />
                                                        <div className="qrt-testimonial-name">
                                                            <h4 className="qrt-mb-5">Emma Newman</h4>
                                                            <div className="qrt-el-suptitle">Chief Architect</div>
                                                        </div>
                                                    </div>
                                                    <div className="qrt-testimonial-text">
                                                        We engaged Paul Trueman of quarty Studio to manage the planning
                                                        process and to design and manage a full renovation and
                                                        remodelling of our 1930s house. We really enjoyed working with
                                                        Paul. We would not hesitate to recommend Paul and Quarty.
                                                    </div>
                                                    <ul className="qrt-stars">
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="swiper-slide">
                                                <div className="qrt-testimonial">
                                                    <div className="qrt-testimonial-header">
                                                        <img src="img/testimonials/t3.jpg" alt="customer" />
                                                        <div className="qrt-testimonial-name">
                                                            <h4 className="qrt-mb-5">Viktoria freeman</h4>
                                                            <div className="qrt-el-suptitle">Chief Architect</div>
                                                        </div>
                                                    </div>
                                                    <div className="qrt-testimonial-text">
                                                        We worked with Quarty Studio for approximately 2 years on the
                                                        complete overhaul of our house. This included the design of the
                                                        project, and the execution of the work. Quarty provided
                                                        excellent design ideas but were also indispensable in managing
                                                        the practical side of the build.
                                                    </div>
                                                    <ul className="qrt-stars">
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li>
                                                            <i className="fas fa-star" />
                                                        </li>
                                                        <li className="qrt-empty">
                                                            <i className="fas fa-star" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="swiper-testi-pagination qrt-cursor-color qrt-cursor-scale" />
                                </div>
                                <div className="col-6">
                                    <div className="qrt-slider-nav">
                                        <div className="qrt-slider-prev qrt-testi-prev qrt-cursor-color qrt-cursor-scale">
                                            <i className="fas fa-arrow-left" />
                                        </div>
                                        <div className="qrt-slider-next qrt-testi-next qrt-cursor-color qrt-cursor-scale">
                                            <i className="fas fa-arrow-right" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="qrt-divider" />
                            {/* <div className="row">
                                <div className="col-lg-12">
                                    <div className="swiper-container qrt-brands-slider" style={{ overflow: 'visible' }}>
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <img src="img/brands/b1.svg" alt="brand" />
                                            </div>
                                            <div className="swiper-slide">
                                                <img src="img/brands/b2.svg" alt="brand" />
                                            </div>
                                            <div className="swiper-slide">
                                                <img src="img/brands/b3.svg" alt="brand" />
                                            </div>
                                            <div className="swiper-slide">
                                                <img src="img/brands/b4.svg" alt="brand" />
                                            </div>
                                            <div className="swiper-slide">
                                                <img src="img/brands/b5.svg" alt="brand" />
                                            </div>
                                            <div className="swiper-slide">
                                                <img src="img/brands/b6.svg" alt="brand" />
                                            </div>
                                            <div className="swiper-slide">
                                                <img src="img/brands/b7.svg" alt="brand" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="qrt-call-to-action">
                                        <h4>Do you have a project?</h4>
                                        <a
                                            className="qrt-btn qrt-btn-sm qrt-btn-color qrt-cursor-scale qrt-anima-link"
                                            href="contact.html"
                                        >
                                            <span>Let's discuss</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="fixed" className="qrt-right"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
