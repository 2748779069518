import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { PAGE_TITLE } from '../../utils/config';

const Index = () => {
    useEffect(() => {
        const mainScript = document.createElement('script');
        mainScript.src = '/js/main.js';
        mainScript.async = true;
        document.body.appendChild(mainScript);
    }, []);
    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE.portfolio}</title>
            </Helmet>
            <div className="qrt-curtain" />
            <div id="qrt-dynamic-content" className="qrt-dynamic-content">
                <div className="qrt-content" id="qrt-scroll-content">
                    <div className="qrt-content-frame">
                        <div className="qrt-left">
                            <div className="qrt-filter qrt-mb-40">
                                <a
                                    href="/#"
                                    data-filter="*"
                                    className="qrt-work-category qrt-current qrt-filter-icon qrt-cursor-color qrt-cursor-scale"
                                >
                                    <i className="fas fa-filter" />
                                    All Categories
                                </a>
                                <a
                                    href="/#"
                                    data-filter=".architecture"
                                    className="qrt-work-category qrt-cursor-color qrt-cursor-scale"
                                >
                                    Architecture
                                </a>
                                <a
                                    href="/#"
                                    data-filter=".interior"
                                    className="qrt-work-category qrt-cursor-color qrt-cursor-scale"
                                >
                                    Interior Design
                                </a>
                                <a
                                    href="/#"
                                    data-filter=".repair"
                                    className="qrt-work-category qrt-cursor-color qrt-cursor-scale"
                                >
                                    Repair
                                </a>
                            </div>
                            <div className="qrt-masonry-grid qrt-mb-40">
                                <div className="qrt-grid-sizer" />
                                <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 architecture">
                                    <div className="qrt-work-item">
                                        <a
                                            data-fancybox="works"
                                            href="img/projects/original-size/1.jpg"
                                            className="qrt-cursor-scale qrt-work-cover-frame"
                                        >
                                            <img src="img/projects/thumbnails/1.jpg" alt="work cover" />
                                            <div className="qrt-item-zoom qrt-cursor-color">
                                                <i className="fas fa-expand" />
                                            </div>
                                            <div className="qrt-work-category">
                                                <span>architecture</span>
                                            </div>
                                        </a>
                                        <div className="qrt-work-descr">
                                            <h4 className="qrt-cursor-color qrt-white">
                                                <Link to="/work?slug=blog-1" className="qrt-anima-link">
                                                    Sunset House
                                                </Link>
                                            </h4>
                                            <Link
                                                to="/work?slug=blog-1"
                                                className="qrt-cursor-scale qrt-work-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 interior architecture">
                                    <div className="qrt-work-item">
                                        <a
                                            data-fancybox="works"
                                            href="img/projects/original-size/2.jpg"
                                            className="qrt-cursor-scale qrt-work-cover-frame"
                                        >
                                            <img src="img/projects/thumbnails/2.jpg" alt="work cover" />
                                            <div className="qrt-item-zoom qrt-cursor-color">
                                                <i className="fas fa-expand" />
                                            </div>
                                            <div className="qrt-work-category">
                                                <span>architecture</span>
                                                <span>interior</span>
                                            </div>
                                        </a>
                                        <div className="qrt-work-descr">
                                            <h4 className="qrt-cursor-color qrt-white">
                                                <a href="single-work-2.html" className="qrt-anima-link">
                                                    MinimalListic interior
                                                </a>
                                            </h4>
                                            <a
                                                href="single-work-2.html"
                                                className="qrt-cursor-scale qrt-work-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 repair">
                                    <div className="qrt-work-item">
                                        <a
                                            data-fancybox="works"
                                            href="img/projects/original-size/3.jpg"
                                            className="qrt-cursor-scale qrt-work-cover-frame"
                                        >
                                            <img src="img/projects/thumbnails/3.jpg" alt="work cover" />
                                            <div className="qrt-item-zoom qrt-cursor-color">
                                                <i className="fas fa-expand" />
                                            </div>
                                            <div className="qrt-work-category">
                                                <span>repair</span>
                                            </div>
                                        </a>
                                        <div className="qrt-work-descr">
                                            <h4 className="qrt-cursor-color qrt-white">
                                                <a href="single-work-1.html" className="qrt-anima-link">
                                                    Rem house
                                                </a>
                                            </h4>
                                            <a
                                                href="single-work-1.html"
                                                className="qrt-cursor-scale qrt-work-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 architecture interior">
                                    <div className="qrt-work-item">
                                        <a
                                            data-fancybox="works"
                                            href="img/projects/original-size/4.jpg"
                                            className="qrt-cursor-scale qrt-work-cover-frame"
                                        >
                                            <img src="img/projects/thumbnails/4.jpg" alt="work cover" />
                                            <div className="qrt-item-zoom qrt-cursor-color">
                                                <i className="fas fa-expand" />
                                            </div>
                                            <div className="qrt-work-category">
                                                <span>architecture</span>
                                                <span>interior</span>
                                            </div>
                                        </a>
                                        <div className="qrt-work-descr">
                                            <h4 className="qrt-cursor-color qrt-white">
                                                <a href="single-work-2.html" className="qrt-anima-link">
                                                    OP House
                                                </a>
                                            </h4>
                                            <a
                                                href="single-work-2.html"
                                                className="qrt-cursor-scale qrt-work-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 interior">
                                    <div className="qrt-work-item">
                                        <a
                                            data-fancybox="works"
                                            href="img/projects/original-size/5.jpg"
                                            className="qrt-cursor-scale qrt-work-cover-frame"
                                        >
                                            <img src="img/projects/thumbnails/5.jpg" alt="work cover" />
                                            <div className="qrt-item-zoom qrt-cursor-color">
                                                <i className="fas fa-expand" />
                                            </div>
                                            <div className="qrt-work-category">
                                                <span>interior</span>
                                            </div>
                                        </a>
                                        <div className="qrt-work-descr">
                                            <h4 className="qrt-cursor-color qrt-white">
                                                <a href="single-work-1.html" className="qrt-anima-link">
                                                    Modern Reception
                                                </a>
                                            </h4>
                                            <a
                                                href="single-work-1.html"
                                                className="qrt-cursor-scale qrt-work-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 repair architecture">
                                    <div className="qrt-work-item">
                                        <a
                                            data-fancybox="works"
                                            href="img/projects/original-size/6.jpg"
                                            className="qrt-cursor-scale qrt-work-cover-frame"
                                        >
                                            <img src="img/projects/thumbnails/6.jpg" alt="work cover" />
                                            <div className="qrt-item-zoom qrt-cursor-color">
                                                <i className="fas fa-expand" />
                                            </div>
                                            <div className="qrt-work-category">
                                                <span>architecture</span>
                                                <span>repair</span>
                                            </div>
                                        </a>
                                        <div className="qrt-work-descr">
                                            <h4 className="qrt-cursor-color qrt-white">
                                                <a href="single-work-2.html" className="qrt-anima-link">
                                                    Villa La Siesta
                                                </a>
                                            </h4>
                                            <a
                                                href="single-work-2.html"
                                                className="qrt-cursor-scale qrt-work-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 architecture repair">
                                    <div className="qrt-work-item">
                                        <a
                                            data-fancybox="works"
                                            href="img/projects/original-size/7.jpg"
                                            className="qrt-cursor-scale qrt-work-cover-frame"
                                        >
                                            <img src="img/projects/thumbnails/7.jpg" alt="work cover" />
                                            <div className="qrt-item-zoom qrt-cursor-color">
                                                <i className="fas fa-expand" />
                                            </div>
                                            <div className="qrt-work-category">
                                                <span>architecture</span>
                                                <span>repair</span>
                                            </div>
                                        </a>
                                        <div className="qrt-work-descr">
                                            <h4 className="qrt-cursor-color qrt-white">
                                                <a href="single-work-1.html" className="qrt-anima-link">
                                                    Terrace garden
                                                </a>
                                            </h4>
                                            <a
                                                href="single-work-2.html"
                                                className="qrt-cursor-scale qrt-work-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 interior">
                                    <div className="qrt-work-item">
                                        <a
                                            data-fancybox="works"
                                            href="img/projects/original-size/8.jpg"
                                            className="qrt-cursor-scale qrt-work-cover-frame"
                                        >
                                            <img src="img/projects/thumbnails/8.jpg" alt="work cover" />
                                            <div className="qrt-item-zoom qrt-cursor-color">
                                                <i className="fas fa-expand" />
                                            </div>
                                            <div className="qrt-work-category">
                                                <span>interior</span>
                                            </div>
                                        </a>
                                        <div className="qrt-work-descr">
                                            <h4 className="qrt-cursor-color qrt-white">
                                                <a href="single-work-2.html" className="qrt-anima-link">
                                                    TZ hotel
                                                </a>
                                            </h4>
                                            <a
                                                href="single-work-2.html"
                                                className="qrt-cursor-scale qrt-work-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 repair architecture">
                                    <div className="qrt-work-item">
                                        <a
                                            data-fancybox="works"
                                            href="img/projects/original-size/9.jpg"
                                            className="qrt-cursor-scale qrt-work-cover-frame"
                                        >
                                            <img src="img/projects/thumbnails/9.jpg" alt="work cover" />
                                            <div className="qrt-item-zoom qrt-cursor-color">
                                                <i className="fas fa-expand" />
                                            </div>
                                            <div className="qrt-work-category">
                                                <span>architecture</span>
                                                <span>repair</span>
                                            </div>
                                        </a>
                                        <div className="qrt-work-descr">
                                            <h4 className="qrt-cursor-color qrt-white">
                                                <a href="single-work-1.html" className="qrt-anima-link">
                                                    Apartment Building
                                                </a>
                                            </h4>
                                            <a
                                                href="single-work-1.html"
                                                className="qrt-cursor-scale qrt-work-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 architecture">
                                    <div className="qrt-work-item">
                                        <a
                                            data-fancybox="works"
                                            href="img/projects/original-size/10.jpg"
                                            className="qrt-cursor-scale qrt-work-cover-frame"
                                        >
                                            <img src="img/projects/thumbnails/10.jpg" alt="work cover" />
                                            <div className="qrt-item-zoom qrt-cursor-color">
                                                <i className="fas fa-expand" />
                                            </div>
                                            <div className="qrt-work-category">
                                                <span>architecture</span>
                                            </div>
                                        </a>
                                        <div className="qrt-work-descr">
                                            <h4 className="qrt-cursor-color qrt-white">
                                                <a href="single-work-2.html" className="qrt-anima-link">
                                                    Weelywally
                                                </a>
                                            </h4>
                                            <a
                                                href="single-work-2.html"
                                                className="qrt-cursor-scale qrt-work-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 interior repair">
                                    <div className="qrt-work-item">
                                        <a
                                            data-fancybox="works"
                                            href="img/projects/original-size/11.jpg"
                                            className="qrt-cursor-scale qrt-work-cover-frame"
                                        >
                                            <img src="img/projects/thumbnails/11.jpg" alt="work cover" />
                                            <div className="qrt-item-zoom qrt-cursor-color">
                                                <i className="fas fa-expand" />
                                            </div>
                                            <div className="qrt-work-category">
                                                <span>interior</span>
                                                <span>repair</span>
                                            </div>
                                        </a>
                                        <div className="qrt-work-descr">
                                            <h4 className="qrt-cursor-color qrt-white">
                                                <a href="single-work-1.html" className="qrt-anima-link">
                                                    Modern country house
                                                </a>
                                            </h4>
                                            <a
                                                href="single-work-1.html"
                                                className="qrt-cursor-scale qrt-work-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 repair interior">
                                    <div className="qrt-work-item">
                                        <a
                                            data-fancybox="works"
                                            href="img/projects/original-size/12.jpg"
                                            className="qrt-cursor-scale qrt-work-cover-frame"
                                        >
                                            <img src="img/projects/thumbnails/4.jpg" alt="work cover" />
                                            <div className="qrt-item-zoom qrt-cursor-color">
                                                <i className="fas fa-expand" />
                                            </div>
                                            <div className="qrt-work-category">
                                                <span>interior</span>
                                                <span>repair</span>
                                            </div>
                                        </a>
                                        <div className="qrt-work-descr">
                                            <h4 className="qrt-cursor-color qrt-white">
                                                <a href="single-work-2.html" className="qrt-anima-link">
                                                    Waterline
                                                </a>
                                            </h4>
                                            <a
                                                href="single-work-2.html"
                                                className="qrt-cursor-scale qrt-work-more qrt-anima-link"
                                            >
                                                <i className="fas fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="qrt-call-to-action">
                                        <h4>Do you have a project?</h4>
                                        <a
                                            className="qrt-btn qrt-btn-sm qrt-btn-color qrt-cursor-scale qrt-anima-link"
                                            href="contact.html"
                                        >
                                            <span>Let's discuss</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="fixed" className="qrt-right"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
