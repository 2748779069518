import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Blog } from '../../enums';
import { CLIENT, CONTACT_INFO } from '../../utils/config';
const Index = () => {
    const [blogDetails, setBlogDetails] = useState<Blog>();
    const [searchParams] = useSearchParams();
    const slug = searchParams.get('slug');

    const fetchBlog = async () => {
        try {
            const response = await fetch(`json/${slug}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            const blog: Blog = await response.json();
            setBlogDetails(blog);
        } catch (error) {
            console.log('something went wrong');
        }
    };

    useEffect(() => {
        slug && fetchBlog();
        const mainScript = document.createElement('script');
        mainScript.src = '/js/main.js';
        mainScript.async = true;
        document.body.appendChild(mainScript);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!blogDetails) {
        return (
            <>
                <div className="qrt-curtain" />
                <div id="qrt-dynamic-content" className="qrt-dynamic-content">
                    <div className="qrt-content" id="qrt-scroll-content">
                        <div className="qrt-half-content-frame">
                            <div className="qrt-left">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="qrt-mb-40">No blog found.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="qrt-curtain" />
            <div id="qrt-dynamic-content" className="qrt-dynamic-content">
                <div className="qrt-content" id="qrt-scroll-content">
                    <div className="qrt-half-content-frame">
                        <div className="qrt-left">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="qrt-mb-40">{blogDetails?.title}</h2>
                                    <div className="qrt-page-cover qrt-cover-center">
                                        <img src="img/projects/thumbnails/2.jpg" alt="sunset house" />
                                        <div className="qrt-about-info qrt-right-position">
                                            <div className="qrt-cover-info">
                                                <ul className="qrt-table">
                                                    <li>
                                                        <h5 className="qrt-white">Order Date:</h5>
                                                        <span>{CONTACT_INFO.address.country}</span>
                                                    </li>
                                                    <li>
                                                        <h5 className="qrt-white">Final Date:</h5>
                                                        <span>{CONTACT_INFO.address.city}</span>
                                                    </li>
                                                    <li>
                                                        <h5 className="qrt-white">Client:</h5>
                                                        <span>{CLIENT}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="qrt-just-text">
                                        <p>{blogDetails?.mainContent}</p>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis culpa
                                            illo optio libero nam, error amet modi explicabo quaerat ratione!
                                        </p>
                                    </div>
                                    <div className="qrt-masonry-grid">
                                        <div className="qrt-grid-sizer" />
                                        <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 interior architecture">
                                            <div className="qrt-work-item">
                                                <a
                                                    data-fancybox="works"
                                                    href="img/projects/original-size/2-3.jpg"
                                                    className="qrt-cursor-scale qrt-work-cover-frame"
                                                >
                                                    <img src="img/projects/thumbnails/2-3.jpg" alt="work cover" />
                                                    <div className="qrt-item-zoom qrt-cursor-color">
                                                        <i className="fas fa-expand" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 repair">
                                            <div className="qrt-work-item">
                                                <a
                                                    data-fancybox="works"
                                                    href="img/projects/original-size/2-4.jpg"
                                                    className="qrt-cursor-scale qrt-work-cover-frame"
                                                >
                                                    <img src="img/projects/thumbnails/2-4.jpg" alt="work cover" />
                                                    <div className="qrt-item-zoom qrt-cursor-color">
                                                        <i className="fas fa-expand" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 architecture interior">
                                            <div className="qrt-work-item">
                                                <a
                                                    data-fancybox="works"
                                                    href="img/projects/original-size/2-5.jpg"
                                                    className="qrt-cursor-scale qrt-work-cover-frame"
                                                >
                                                    <img src="img/projects/thumbnails/2-5.jpg" alt="work cover" />
                                                    <div className="qrt-item-zoom qrt-cursor-color">
                                                        <i className="fas fa-expand" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 qrt-masonry-grid-item-h-x-2 interior">
                                            <div className="qrt-work-item">
                                                <a
                                                    data-fancybox="works"
                                                    href="img/projects/original-size/2-6.jpg"
                                                    className="qrt-cursor-scale qrt-work-cover-frame"
                                                >
                                                    <img src="img/projects/thumbnails/2-6.jpg" alt="work cover" />
                                                    <div className="qrt-item-zoom qrt-cursor-color">
                                                        <i className="fas fa-expand" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 qrt-masonry-grid-item-h-x-2 repair architecture">
                                            <div className="qrt-work-item">
                                                <a
                                                    data-fancybox="works"
                                                    href="img/projects/original-size/2-7.jpg"
                                                    className="qrt-cursor-scale qrt-work-cover-frame"
                                                >
                                                    <img src="img/projects/thumbnails/2-7.jpg" alt="work cover" />
                                                    <div className="qrt-item-zoom qrt-cursor-color">
                                                        <i className="fas fa-expand" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 architecture repair">
                                            <div className="qrt-work-item">
                                                <a
                                                    data-fancybox="works"
                                                    href="img/projects/original-size/2-8.jpg"
                                                    className="qrt-cursor-scale qrt-work-cover-frame"
                                                >
                                                    <img src="img/projects/thumbnails/2-8.jpg" alt="work cover" />
                                                    <div className="qrt-item-zoom qrt-cursor-color">
                                                        <i className="fas fa-expand" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 architecture repair">
                                            <div className="qrt-work-item">
                                                <a
                                                    data-fancybox="works"
                                                    href="img/projects/original-size/2-2.jpg"
                                                    className="qrt-cursor-scale qrt-work-cover-frame"
                                                >
                                                    <img src="img/projects/thumbnails/2-2.jpg" alt="work cover" />
                                                    <div className="qrt-item-zoom qrt-cursor-color">
                                                        <i className="fas fa-expand" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 qrt-masonry-grid-item-h-x-2 architecture repair">
                                            <div className="qrt-work-item">
                                                <a
                                                    data-fancybox="works"
                                                    href="img/projects/original-size/2.jpg"
                                                    className="qrt-cursor-scale qrt-work-cover-frame"
                                                >
                                                    <img src="img/projects/thumbnails/2.jpg" alt="work cover" />
                                                    <div className="qrt-item-zoom qrt-cursor-color">
                                                        <i className="fas fa-expand" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="qrt-masonry-grid-item qrt-masonry-grid-item-50 architecture repair">
                                            <div className="qrt-work-item">
                                                <a
                                                    data-fancybox="works"
                                                    href="img/projects/original-size/2-9.jpg"
                                                    className="qrt-cursor-scale qrt-work-cover-frame"
                                                >
                                                    <img src="img/projects/thumbnails/2-9.jpg" alt="work cover" />
                                                    <div className="qrt-item-zoom qrt-cursor-color">
                                                        <i className="fas fa-expand" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="qrt-just-text">
                                        <p>{blogDetails?.footerContent}</p>
                                        <p>Eerror amet modi explicabo quaerat ratione!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="qrt-divider qrt-space-fix" />

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="qrt-call-to-action">
                                        <h4>Do you have a project?</h4>
                                        <a
                                            className="qrt-btn qrt-btn-sm qrt-btn-color qrt-cursor-scale qrt-anima-link"
                                            href="contact.html"
                                        >
                                            <span>Let's discuss</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="fixed" className="qrt-right">
                            <div className="qrt-half-banner qrt-left-align qrt-animated-zoom">
                                <div className="qrt-image-frame">
                                    <img src="img/banners/banner3.jpg" alt="banner" />
                                </div>
                                <div className="qrt-overlay">
                                    <div className="qrt-scroll-hint">
                                        <span />
                                    </div>
                                    <div className="qrt-banner-title">
                                        <h2 className="qrt-white qrt-mb-10">Our Works</h2>
                                        <div className="qrt-divider-2" />
                                        <div className="qrt-text qrt-white qrt-mb-20">
                                            Dolor sit amet, consectetur adipisicing elit. <br />
                                            Vel excepturi, earum inventore.
                                        </div>
                                        <a
                                            href="contact.html"
                                            className="qrt-btn qrt-btn-md qrt-btn-color qrt-anima-link"
                                        >
                                            <span>Get in touch</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
