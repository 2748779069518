import { Link, useLocation } from 'react-router-dom';

const Index = () => {
    const { pathname } = useLocation();

    return (
        <>
            <div className="qrt-preloader">
                <div className="qrt-preloader-content">
                    <div className="qrt-logo">
                        <img src="img/logo.svg" alt="Quarty" />
                    </div>
                    <div id="preloader" className="qrt-preloader-load" />
                </div>
            </div>
            <div id="cursor" className="qrt-cursor">
                <div />
                <div className="qrt-follower">
                    <i className="fas fa-circle-notch" />
                </div>
            </div>
            <div className="qrt-top-bar">
                <a href="home-video.html" className="qrt-symbol qrt-cursor-scale qrt-anima-link">
                    <img src="img/logo-sm.svg" alt="Symbol" />
                </a>
                <a href="home-video.html" className="qrt-logo qrt-cursor-color qrt-cursor-scale qrt-anima-link">
                    <img src="img/logo.svg" alt="Quarty" />
                </a>
                <div className="qrt-menu">
                    <div id="qrt-dynamic-menu" className="qrt-dynamic-menu">
                        <nav>
                            <ul>
                                <li className={pathname === '/' ? 'current-menu-item' : ''}>
                                    <Link className="qrt-mobile-fix" to="/">
                                        Home
                                    </Link>
                                </li>
                                <li className={pathname === '/about' ? 'current-menu-item' : ''}>
                                    <Link className="qrt-mobile-fix" to="/about">
                                        About
                                    </Link>
                                </li>
                                <li className={pathname === '/portfolio' ? 'current-menu-item' : ''}>
                                    <Link className="qrt-mobile-fix" to="/portfolio">
                                        Portfolio
                                    </Link>
                                </li>
                                <li className={pathname === '/contact' ? 'current-menu-item' : ''}>
                                    <Link className="qrt-mobile-fix" to="/contact">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="qrt-buttons">
                    <a href="/#" className="qrt-btn qrt-btn-md qrt-btn-color qrt-send-request qrt-anima-link qrt-mr-20">
                        <span>Send request</span>
                    </a>
                    <div className="qrt-menu-btn qrt-cursor-color qrt-cursor-scale">
                        <span />
                    </div>

                    <div className="qrt-info-btn qrt-cursor-color qrt-cursor-scale">
                        <span />
                    </div>
                </div>

                <div className="qrt-info" id="qrt-scroll-info">
                    <div className="qrt-info-frame">
                        <ul className="qrt-table">
                            <li>
                                <h5>Country</h5>
                                <span>Canada</span>
                            </li>
                            <li>
                                <h5>City</h5>
                                <span>Toronto</span>
                            </li>
                            <li>
                                <h5>Street</h5>
                                <span>North Avenue 31B, </span>
                            </li>
                            <li>
                                <h5>Email</h5>
                                <span>
                                    <a
                                        className="qrt-cursor-scale qrt-cursor-color"
                                        href="mailto:quarty.inbox@mail.com"
                                        data-no-swup=""
                                    >
                                        quarty.inbox@mail.com
                                    </a>
                                </span>
                            </li>
                            <li>
                                <h5>Phone</h5>
                                <span>
                                    <a className="qrt-cursor-scale qrt-cursor-color" href="#." data-no-swup="">
                                        +9 3(049) 482 95 23
                                    </a>
                                </span>
                            </li>
                        </ul>
                        <div className="qrt-divider" />
                        <div className="qrt-social-links">
                            <div className="qrt-social-links">
                                <h5 className="qrt-mb-40">We are social</h5>
                                <ul className="qrt-social-list">
                                    <li>
                                        <a href="#." className="qrt-cursor-scale qrt-cursor-color">
                                            <i className="fab fa-behance" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#." className="qrt-cursor-scale qrt-cursor-color">
                                            <i className="fab fa-dribbble" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#." className="qrt-cursor-scale qrt-cursor-color">
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#." className="qrt-cursor-scale qrt-cursor-color">
                                            <i className="fab fa-linkedin-in" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="qrt-divider" />
                        <div className="qrt-instagram">
                            <h5 className="qrt-mb-40">Instagram</h5>
                            <div className="qrt-instagram-frame">
                                <a href="#." className="qrt-instagram-item qrt-cursor-scale">
                                    <div className="qrt-instagram-image-frame">
                                        <img src="img/projects/thumbnails/7.jpg" alt="instagram post" />
                                    </div>
                                </a>
                                <a href="#." className="qrt-instagram-item qrt-cursor-scale">
                                    <div className="qrt-instagram-image-frame">
                                        <img src="img/projects/thumbnails/8.jpg" alt="instagram post" />
                                    </div>
                                </a>
                                <a href="#." className="qrt-instagram-item qrt-cursor-scale">
                                    <div className="qrt-instagram-image-frame">
                                        <img src="img/projects/thumbnails/9.jpg" alt="instagram post" />
                                    </div>
                                </a>
                                <a href="#." className="qrt-instagram-item qrt-cursor-scale">
                                    <div className="qrt-instagram-image-frame">
                                        <img src="img/projects/thumbnails/10.jpg" alt="instagram post" />
                                    </div>
                                </a>
                                <a href="#." className="qrt-instagram-item qrt-cursor-scale">
                                    <div className="qrt-instagram-image-frame">
                                        <img src="img/projects/thumbnails/11.jpg" alt="instagram post" />
                                    </div>
                                </a>
                                <a href="#." className="qrt-instagram-item qrt-cursor-scale">
                                    <div className="qrt-instagram-image-frame">
                                        <img src="img/projects/thumbnails/12.jpg" alt="instagram post" />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="qrt-divider" />
                        <h5 className="qrt-mb-40">Latest Publications</h5>
                        <div className="qrt-post-frame qrt-sm-post">
                            <a href="publication.html" className="qrt-post-thumb qrt-cursor-scale qrt-anima-link">
                                <img src="img/blog/1.jpg" alt="thumbnail" />
                            </a>
                            <div className="qrt-post-descr">
                                <div>
                                    <h4 className="qrt-cursor-color qrt-post-title">
                                        <a href="publication.html" className="qrt-anima-link">
                                            Architecture of Observation Towers
                                        </a>
                                    </h4>
                                    <div className="qrt-port-short-text">
                                        It seems to be human nature to enjoy a view, getting the higher ground and
                                        taking in our surroundings has become a significant aspect of architecture
                                        across the world. Observation towers which allow visitors to climb and observe
                                        their surroundings, provide a chance to take in the beauty of the land while at
                                        the same time adding something unique and impressive to the landscape.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="qrt-post-frame qrt-sm-post">
                            <a href="publication.html" className="qrt-post-thumb qrt-cursor-scale qrt-anima-link">
                                <img src="img/blog/2.jpg" alt="thumbnail" />
                            </a>
                            <div className="qrt-post-descr">
                                <div>
                                    <h4 className="qrt-cursor-color qrt-post-title">
                                        <a href="publication.html" className="qrt-anima-link">
                                            Model Making In Architecture
                                        </a>
                                    </h4>
                                    <div className="qrt-port-short-text">
                                        The importance of model making in architecture could be thought to have reduced
                                        in recent years. With the introduction of new and innovative architecture design
                                        technology, is there still a place for model making in architecture? Stanton
                                        Williams, director at Stirling Prize-winning practice, Gavin Henderson, believes
                                        that it’s more important than ever.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="qrt-post-frame qrt-sm-post">
                            <a href="publication.html" className="qrt-post-thumb qrt-cursor-scale qrt-anima-link">
                                <img src="img/blog/5.jpg" alt="thumbnail" />
                            </a>
                            <div className="qrt-post-descr">
                                <div>
                                    <h4 className="qrt-cursor-color qrt-post-title">
                                        <a href="publication.html" className="qrt-anima-link">
                                            Can Skyscrapers Be Sustainable
                                        </a>
                                    </h4>
                                    <div className="qrt-port-short-text">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad, id, reprehenderit
                                        earum quidem error hic deserunt asperiores suscipit. Magni doloribus, ab cumque
                                        modi quidem doloremque nostrum quam tempora, corporis explicabo nesciunt
                                        accusamus ad architecto sint voluptatibus tenetur ipsa hic eius.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="qrt-divider" />
                        <div className="qrt-subscribe">
                            <h5 className="qrt-mb-40">Subscribe our newsletter</h5>
                            <form>
                                <input type="text" placeholder="Email" />
                                <button type="submit" className="qrt-btn qrt-btn-sm">
                                    <i className="far fa-paper-plane" />
                                </button>
                            </form>
                        </div>
                        <div className="qrt-divider" />
                        <div className="qrt-copy">
                            {/* author ( Please! Do not delete it. You are awesome! :) */}
                            <div>
                                © Late 2020 Quarty.
                                <br />
                                Design by:&nbsp;{' '}
                                <a
                                    className="qrt-cursor-scale qrt-cursor-color"
                                    href="https://themeforest.net/user/millerdigitaldesign"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Nazar Miller
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="qrt-left-bar">
                <div className="qrt-current-page-title" />
                <div className="qrt-change-language">
                    {/* <a href="#." className="qrt-leng qrt-cursor-color qrt-cursor-scale">
                        fr
                    </a> */}
                    <a href="#." className="qrt-leng qrt-active">
                        En
                    </a>
                </div>
            </div>
        </>
    );
};

export default Index;
