import Navbar from './components/Navbar';
import Home from './pages/Home';
import Work from './pages/Work';
import About from './pages/About';
import PortFolio from './pages/PortFolio';
import Contact from './pages/Contact';
import { Route, Routes } from 'react-router-dom';

const App = () => {
    return (
        <div className="qrt-app">
            <Navbar />
            <Routes>
                <Route path="/work" element={<Work />} />
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/portfolio" element={<PortFolio />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </div>
    );
};

export default App;
