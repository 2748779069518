import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { CONTACT_INFO, PAGE_TITLE } from '../../utils/config';

const Index = () => {
    useEffect(() => {
        const mainScript = document.createElement('script');
        mainScript.src = '/js/main.js';
        mainScript.async = true;
        document.body.appendChild(mainScript);
    }, []);
    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE.contact}</title>
            </Helmet>
            <div className="qrt-curtain" />
            <div id="qrt-dynamic-content" className="qrt-dynamic-content">
                <div className="qrt-content" id="qrt-scroll-content">
                    <div className="qrt-half-content-frame">
                        <div className="qrt-left">
                            <div className="row qrt-p-0-40">
                                <div className="col-lg-12">
                                    <div className="qrt-page-cover">
                                        <img src="img/banners/cover2.jpg" alt="our office" />
                                        <div className="qrt-about-info">
                                            <div className="qrt-cover-info">
                                                <ul className="qrt-table">
                                                    <li>
                                                        <h5 className="qrt-white">Main office</h5>
                                                        <span>
                                                            {CONTACT_INFO.address.city}, {CONTACT_INFO.address.country}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <h5 className="qrt-white">Adress:</h5>
                                                        <a
                                                            href="https://goo.gl/maps/MAa6Au5d9ZMgSfBV7"
                                                            target="_blank"
                                                            data-no-swup=""
                                                            rel="noreferrer"
                                                        >
                                                            <span>{CONTACT_INFO.address.mainAddress}</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3 className="qrt-mb-40">Contact info</h3>
                                </div>
                                <div className="col-lg-4">
                                    <div className="qrt-icon-box">
                                        {/* icon */}
                                        <img className="mb-20" src="img/icons/icon-4.svg" alt="icon" />
                                        {/* title */}
                                        <h4 className="mb-20">Phone number</h4>
                                        {/* description */}
                                        <a href="#." data-no-swup="">
                                            <span>{CONTACT_INFO.phone.firstPhone}</span>
                                        </a>
                                        <br />
                                        <a href="tel:+9 3(046) 482 88 34" data-no-swup="">
                                            <span>{CONTACT_INFO.phone.secondPhone}</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="qrt-icon-box">
                                        {/* icon */}
                                        <img className="mb-20" src="img/icons/icon-5.svg" alt="icon" />
                                        {/* title */}
                                        <h4 className="mb-20">Email</h4>
                                        <a href="mailto:quarty.inbox@mail.com" data-no-swup="">
                                            <span>{CONTACT_INFO.email.firstEmail}</span>
                                        </a>
                                        <br />
                                        <a href="mailto:quarty.office.inbox@mail.com" data-no-swup="">
                                            <span>{CONTACT_INFO.email.secondEmail}</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="qrt-icon-box">
                                        {/* icon */}
                                        <img className="mb-20" src="img/icons/icon-6.svg" alt="icon" />
                                        {/* title */}
                                        <h4 className="mb-20">Location</h4>
                                        <a
                                            href="https://goo.gl/maps/MAa6Au5d9ZMgSfBV7"
                                            target="_blank"
                                            data-no-swup=""
                                            rel="noreferrer"
                                        >
                                            <span>
                                                {CONTACT_INFO.address.mainAddress}, <br />
                                                {CONTACT_INFO.address.city}, {CONTACT_INFO.address.country}
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="qrt-divider qrt-space-fix" />
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3 className="qrt-mb-40">Get in touch</h3>
                                </div>
                                <div className="col-lg-12">
                                    <form id="form" className="qrt-contact-form">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="qrt-form-field">
                                                    <input
                                                        id="name"
                                                        name="name"
                                                        className="qrt-input"
                                                        type="text"
                                                        placeholder="Name"
                                                        required
                                                    />
                                                    <label htmlFor="name">
                                                        <i className="fas fa-user" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="qrt-form-field">
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        className="qrt-input"
                                                        type="email"
                                                        placeholder="Email"
                                                        required
                                                    />
                                                    <label htmlFor="email">
                                                        <i className="fas fa-at" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="qrt-form-field">
                                                    <textarea
                                                        id="message"
                                                        name="text"
                                                        className="qrt-input"
                                                        placeholder="Message"
                                                        required
                                                        defaultValue={''}
                                                    />
                                                    <label htmlFor="message">
                                                        <i className="far fa-envelope" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="qrt-mb-20">
                                                    We promise not to disclose your personal information to third
                                                    parties.
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="qrt-submit-frame qrt-text-right qrt-sm-text-left qrt-mb-40">
                                                    <button
                                                        className="qrt-btn qrt-btn-md qrt-btn-color qrt-cursor-scale"
                                                        type="submit"
                                                    >
                                                        <span>Send message</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="qrt-divider qrt-space-fix" />
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3 className="qrt-mb-40">Welcome to visit</h3>
                                </div>
                                <div className="col-12">
                                    <div className="qrt-map-frame qrt-map-frame-2">
                                        <div className="qrt-lock">
                                            <i className="fas fa-lock" />
                                        </div>
                                        <div id="map" className="qrt-map" />
                                    </div>
                                </div>
                            </div>
                            <div className="qrt-divider" />
                            {/* <div className="row">
                                    <div className="col-lg-12">
                                        <div
                                            className="swiper-container qrt-brands-slider"
                                            style={{ overflow: 'visible' }}
                                        >
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <img src="img/brands/b1.svg" alt="brand" />
                                                </div>
                                                <div className="swiper-slide">
                                                    <img src="img/brands/b2.svg" alt="brand" />
                                                </div>
                                                <div className="swiper-slide">
                                                    <img src="img/brands/b3.svg" alt="brand" />
                                                </div>
                                                <div className="swiper-slide">
                                                    <img src="img/brands/b4.svg" alt="brand" />
                                                </div>
                                                <div className="swiper-slide">
                                                    <img src="img/brands/b5.svg" alt="brand" />
                                                </div>
                                                <div className="swiper-slide">
                                                    <img src="img/brands/b6.svg" alt="brand" />
                                                </div>
                                                <div className="swiper-slide">
                                                    <img src="img/brands/b7.svg" alt="brand" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="qrt-call-to-action">
                                        <h4>Learn more about us!</h4>
                                        <a
                                            className="qrt-btn qrt-btn-sm qrt-btn-color qrt-cursor-scale qrt-anima-link"
                                            href="about-us.html"
                                        >
                                            <span>About us</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="fixed" className="qrt-right">
                            <div className="qrt-half-banner qrt-left-align qrt-animated-show">
                                <div className="qrt-image-frame">
                                    <img src="img/banners/banner4.jpg" alt="banner" />
                                </div>
                                <div className="qrt-overlay">
                                    <div className="qrt-scroll-hint">
                                        <span />
                                    </div>
                                    <div className="qrt-banner-title">
                                        <h2 className="qrt-white qrt-mb-10">Get in touch</h2>
                                        <div className="qrt-divider-2" />
                                        <div className="qrt-text qrt-white qrt-mb-20">
                                            Dolor sit amet, consectetur adipisicing elit. <br />
                                            Vel excepturi, earum inventore.
                                        </div>
                                        <a
                                            href="portfolio-half-masonry-1.html"
                                            className="qrt-btn qrt-btn-md qrt-btn-color qrt-anima-link"
                                        >
                                            <span>Portfolio</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
