const COMPANY_NAME = 'Konark';

export const PAGE_TITLE = {
    portfolio: `${COMPANY_NAME} | Portfolio`,
    home: `${COMPANY_NAME} | Home`,
    contact: `${COMPANY_NAME} | Contact`,
    about: `${COMPANY_NAME} | About`,
    work: `${COMPANY_NAME} | Work`,
};

export const CONTACT_INFO = {
    email: {
        firstEmail: 'konarkinfo@gmail.com',
        secondEmail: 'contactKonark@gmail.com',
    },
    phone: {
        firstPhone: '+91 7485256985',
        secondPhone: '+91 7485276582',
    },
    address: {
        city: 'Surat',
        country: 'India',
        mainAddress: '901, Orbit-2, Vesu 395007',
    },
};

export const CLIENT = 'Envato Market';
